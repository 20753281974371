<div>

    <p *ngIf="!updateCandidate" class="mb-5 ml-4 margin-top-20 align-left">{{'RequestWizzardUpdateHelp' | localize}}</p>

    <div *ngIf="updateCandidate">
        <p class="mb-5 ml-4 margin-top-20 align-left alert alert-warning">{{'RequestWizzardCreationHelp' | localize}}</p>
        <p class="col-md-9 align-left">{{'RequestWizzardFillMandatoryFields' | localize}}</p>
    </div>

    <form [formGroup]="form" autocomplete="off" class="card card-custom">
        <div class="form-inline">
            <div class="form-group col-md-3 inline-block align-left px-3">
                <label for="lastName" class="block">{{"LastName" | localize}} <span
                        *ngIf="updateCandidate && canUpdateBasicInfos" class="asterix">*</span></label>
                <input id="lastName" formControlName="lastName" type="text" name="lastName" class="form-control"
                    minlenth="1" maxlength="256" required>
            </div>
            <div class="form-group col-md-3 inline-block align-left px-3">
                <label for="firstName" class="block">{{"FirstName" | localize}} <span
                        *ngIf="updateCandidate && canUpdateBasicInfos" class="asterix">*</span></label>
                <input id="firstName" formControlName="firstName" type="text" name="firstName" class="form-control"
                    minlength="1" maxlength="256" required>
            </div>
            <div class="form-group col-md-2 inline-block align-left px-3">
                <label for="birthdate" class="block">{{"Birthdate" | localize}} {{'FormatDate' | localize}}
                    <span *ngIf="updateCandidate && canUpdateBasicInfos" class="asterix">*</span>
                </label>
                <input id="birthdate" formControlName="birthdate" type="text" name="birthdate" class="form-control"
                    bsDatepicker [maxDate]="maxDate"
                    [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
                    required>
            </div>
            <div class="form-group col-md-2 inline-block align-left px-3">
                <label for="sex" class="block">{{"Sex" | localize}}
                    <span *ngIf="updateCandidate && canUpdateBasicInfos" class="asterix">*</span>
                </label>
                <select id="sex" formControlName="sex" type="sex" name="sex" class="form-control" required>
                    <option value='0'>{{"Male" | localize}}</option>
                    <option value='1'>{{"Female" | localize}}</option>
                    <option value='2'>{{"Other" | localize}}</option>
                </select>
            </div>
            <div class="form-group col-md-2 inline-block align-left">
                <label for="language" class="block">{{"Language" | localize}}
                    <span *ngIf="updateCandidate && canUpdateBasicInfos" class="asterix">*</span>
                </label>
                <select id="language" formControlName="language" type="language" name="language" class="form-control" required>
                    <option value='0'>{{"French" | localize}}</option>
                    <option value='1'>{{"English" | localize}}</option>
                </select>
            </div>
        </div>
        <request-wizard-candidate-basic-infos [(form)]="candidateform" [(request)]="request"
            [resetDriverLicenseRequired]="resetDriverLicenseRequired" [(updateCandidateInfos)]="updateCandidate">
        </request-wizard-candidate-basic-infos>
        <div *ngIf="updateCandidate" class="form-inline justify-content-end">
            <div role="actions">
                <div class="row">
                    <div class="col-lg-10"></div>
                    <div class="col-lg-1 justify-content-center align-self-center pr-0">
                        <label for="btn-update" class="float-lg-end labelConfirm vertical-align" >{{"RequestWizzardConfirm" | localize}}</label>
                    </div>
                    <div class="col-lg-1 pl-0 align-self-center">
                        <button type="submit" id="btn-update" name="btn-update"
                            class="inline-block align-start btn-update pl-0 ml-0 float-lg-start "
                            [disabled]="updateCandidate && (candidateform.invalid || form.invalid)" (click)="update()">
                            <i class="la la-check-circle btn-update"></i>
                        </button>
                        <button type="button" id="btn-cancel" name="btn-cancel"
                            class="inline-block align-start btn-cancel pl-0 ml-0 float-lg-start " (click)="cancel()">
                            <i class="la la-times-circle btn-cancel"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!updateCandidate">
            <div class="col-md-10 align-start"></div>
            <div class="col-md-2 d-flex float-end" role="actions">
                <button *ngIf="!this.requestWizard.isRequestSendGuardium()" type="submit" id="btn-edit" name="btn-edit"
                    class="inline-block margin-top-20 btn-edit wizard-btn d-flex justify-content-end" (click)="edit()">
                    <span class="wizard-btn-text">{{'RequestWizardEdit' | localize}}</span>
                    <i class="la la-edit btn-edit"></i>
                </button>
            </div>
        </div>
    </form>
    <div class="row mt-5">
        <div class="col-md-6 d-flex float-end">
            <label for="changeCandidateBtn" class="margin-right-20 justify-content-center align-self-center ">{{'RequestWizzardChangeCandidateQuestion' | localize}}</label>
            <button class="btn btn-secondary inline-block" id="changeCandidateBtn" [disabled]="updateCandidate || !this.requestWizard.isRequestDraft()" (click)="changeCandidate()">
                {{'RequestWizzardChangeCandidate' | localize}}
            </button>
        </div>
        <div class="col-md-6 align-start"></div>
    </div>
    <div class="d-flex mt-10">
        <div class="">
            <button class="float-lg-right btn-back p-0 mr-2" [disabled]="updateCandidate" awPreviousStep>
                <i class="la la-arrow-circle-o-left btn-back float-lg-right"></i>
            </button>
        </div>
        <div class="text-left justify-content-center align-self-center pl-0">
            <label for="saveBtn" class="mb-0">{{"RequestWizzardPreviousStep" | localize}}</label>
        </div>
        <div class="flex-grow-1">
        </div>
        <div class="text-end justify-content-center align-self-center pr-0">
            <label for="saveBtn" class="mb-0">{{"RequestWizzardNextStep" | localize}}</label>
        </div>
        <div class="d-inline-block pl-0" tabindex="0" data-toggle="tooltip" title="{{'RequestWizzardUpdateCandidateNext' | localize}}" data-placement="top">
            <button id="saveBtn" class="float-lg-left btn-next p-0 ml-2" [disabled]="updateCandidate" (click)="save()" awNextStep>
                <i class="la la-arrow-circle-o-right btn-next float-lg-left"></i>
            </button>
        </div>
    </div>
</div>
