import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export type Case = 'title' | 'upper' | 'lower';

@Directive({
    selector: '[appTextTransform]'
})
export class TextTransformDirective {

    @Input() public appTextTransform: Case;
    private isInternalChange = false;

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) public onInput(event: Event) {
        if (!this.appTextTransform || this.isInternalChange) {
            this.isInternalChange = false;
            return;
        }

        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;
        let transformedValue: string;
        switch (this.appTextTransform) {
            case 'title':
                transformedValue = inputValue.split(" ")
                    .map((word) =>
                        this.isNullOrEmpty(word) ?
                            word :
                            word[0].toUpperCase() + word.substring(1).toLowerCase())
                    .join(" ");
                break;
            case 'upper':
                transformedValue = inputValue.toUpperCase();
                break;
            case 'lower':
                transformedValue = inputValue.toLowerCase();
                break;
            default:
                transformedValue = inputValue;
        }

        // Update input value
        inputElement.value = transformedValue;

        // Manually dispatch a new 'input' event to notify Angular of a change and propagate it to the bound model
        this.isInternalChange = true;
        const newEvent = new Event('input', { bubbles: true });
        inputElement.dispatchEvent(newEvent);
    }

    private isNullOrEmpty(inputText: string): boolean {
        return inputText === null || inputText === '';
    }
}
