import { Component, OnInit, Injector, Input, ViewChild, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '@shared/services/invoice.service';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { ValueFormatterParams, GridOptions, GridApi } from '@ag-grid-enterprise/all-modules';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Extensions } from '@shared/utils/extensions';
import { InvoiceGridOutput } from '@shared/models/InvoiceElements/invoice/invoiceGridOutput';
import { InvoiceDataSourceService } from '@shared/services/invoice-data-source.service';
import { debounce } from '@metronic/common/js/components/util';
import { InvoicePreAuthorizedPayment } from '@shared/models/InvoiceElements/invoice/InvoicePreAuthorizedPayment';

@Component({
    selector: 'invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss'],
    providers: [DatePipe],
})
export class InvoiceListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() organizationPublicId: string = null;

    public gridOptions: GridOptions;
    public defaultColDef;
    paginationNumberFormatter;
    paginationPageSize = 20;

    filterText = sessionStorage.getItem('invoice-search-text') ?? '';
    rowData: InvoiceGridOutput[] = [];

    selectedInvoice: InvoiceGridOutput;
    rowSelection: string;
    selectedInvoiceRow: any;
    columnDefs: any;
    organization: organizationOutput;
    private gridApi: GridApi;
    private searchTimeout: ReturnType<typeof setTimeout>;

    constructor(
        injector: Injector,
        private service: InvoiceService,
        private _router: Router,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private datePipe: DatePipe,
        private _invoiceDatasourceService: InvoiceDataSourceService
    ) {
        super(injector);
        this.rowSelection = this.isGranted('Pages.Administration.Invoices.Details') ? 'single' : null;

        this.gridOptions = <GridOptions>{
            localeTextFunc: this.localizeGridText.bind(this),
            rowModelType: 'serverSide',
            animateRows: true,
            cacheBlockSize: this.paginationPageSize,
            groupSuppressAutoColumn: true,
            blockLoadDebounceMillis: 200,
            defaultExportParams: {
                processCellCallback: (params) => {
                    const colDef = params.column.getColDef();
                    if (colDef.valueFormatter) {
                        const valueFormatterParams: ValueFormatterParams = {
                            ...params,
                            data: params.node.data,
                            node: params.node,
                            colDef: params.column.getColDef()
                        };
                        return colDef.valueFormatter(valueFormatterParams);
                    }
                    return params.value;
                }
            }
        };
        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi && screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    ngOnInit() {
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 200,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('Organization'),
                field: 'clientId',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('InvoiceNumber'),
                field: 'invoiceNumber',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.invoiceNumberFormatter,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('InvoiceDate'),
                field: 'invoiceDate',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                cellDataType: 'date',
                sort: 'desc',
                valueFormatter: this.dateFormater.bind(this),
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('SubTotal'),
                filter: 'agNumberColumnFilter',
                cellDataType: 'number',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                field: 'subTotal',
                valueFormatter: this.subTotalCurrencyFormat,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Total'),
                filter: 'agNumberColumnFilter',
                cellDataType: 'number',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                field: 'total',
                valueFormatter: this.totalCurrencyFormat,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                field: 'status',
                valueFormatter: this.statusGetter.bind(this),
                width: 175,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: this.numericPicklistValues(InvoiceStatusEnum),
                    suppressAndOrCondition: true
                }
            },
            // invisible column, for passing global filter in data fetching calls
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: { filterOption: ['contains'] },
                flex: 1,
                hide: true,
                lockVisible: true
            }
        ];

        if (this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')) {
            this.columnDefs.push({
                field: 'preAuthorized',
                headerName: this._appLocalizationService.l('PreAuthorized'),
                valueFormatter: this.preAuthorizedFormatter.bind(this),
                width: 200,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: this.numericPicklistValues(InvoicePreAuthorizedPayment), //['No', 'Yes', 'PreAuthorizedNonApplicable'].map((key) => this.l(key)),
                    suppressAndOrCondition: true
                }
            });
        }
        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    numericPicklistValues<TEnum extends typeof InvoiceStatusEnum | typeof InvoicePreAuthorizedPayment>(enumType: TEnum) {
        const numericValues = Object.values(enumType).filter((value) => typeof value === 'number');
        return numericValues;
    }

    statusGetter(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(InvoiceStatusEnum[params.value]);
    }

    preAuthorizedFormatter(params: ValueFormatterParams): string {
        const value = params.value as string;
        if (/^\d+$/.test(value)) {
            // string representation of numeric value from column's (filterParams.values) in (this.columnDefs)
            const labelKeys = ['PreAuthorizedNonApplicable', 'No', 'Yes'];
            const labelKey = labelKeys[value] as string;
            return labelKey && this.l(labelKey);
        } else {
            // data from backend
            switch (value) {
                case 'False':
                    return this.l('No');
                case 'True':
                    return this.l('Yes');
                default:
                    return this.l('PreAuthorizedNonApplicable');
            }
        }
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    invoiceNumberFormatter(params) {
        const invoiceNumber = params.data?.invoiceNumber;
        if (invoiceNumber) {
            const firstThree = invoiceNumber.substring(0, 3);
            const secondThree = invoiceNumber.substring(3, 6);
            const thirdThree = invoiceNumber.substring(6, 9);
            return firstThree + ' ' + secondThree + ' ' + thirdThree;
        }
        return invoiceNumber;
    }

    static currencyFormat(value) {
        return typeof value == 'number' ? value.toFixed(2) + ' $' : '';
    }

    subTotalCurrencyFormat(params) {
        const subTotal = params.data?.subTotal;
        return InvoiceListComponent.currencyFormat(subTotal);
    }

    totalCurrencyFormat(params) {
        const total = params.data?.total;
        return InvoiceListComponent.currencyFormat(total);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.loadGridParametersFromStorage();
        this.agGrid.api.setServerSideDatasource(this._invoiceDatasourceService);
        this._invoiceDatasourceService.gridReadySubject.next();
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(params.value, AppConsts.dateTimeFormat);
    }

    localizeGridText(key: string, defaultValue: string) {
        const gridKey = 'grid.' + key;
        const translatedText = this._appLocalizationService.l(gridKey, AppConsts.localization.defaultLocalizationSourceName);
        return translatedText === gridKey ? defaultValue : translatedText;
    }

    refresh() {
        this.filterText = '';
        clearTimeout(this.searchTimeout);
        this.searchEffective();
    }

    search() {
        this.searchTimeout = debounce(this.searchTimeout, this.searchEffective.bind(this), 350);
    }
    searchEffective() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        const gridOptions = this.agGrid.gridOptions;

        const filterGlobal = gridOptions.api.getFilterInstance('globalFilter');
        if (filterGlobal.getModel()?.filter !== filterWithoutAccents) {
            filterGlobal.setModel({
                filterType: 'text',
                type: 'contains',
                filter: filterWithoutAccents
            });
            gridOptions.api.onFilterChanged();
        }
        sessionStorage.setItem('invoice-search-text', filterWithoutAccents);
    }

    /**
     * @returns grid's filters and sorting parameters
     */
    getGridFilterAndSort() {
        const filterModel = this.gridApi.getFilterModel();
        const sortModel = this.gridApi.getSortModel();
        return { filterModel, sortModel };
    }

    onSelectionChanged(): void {
        this.selectedInvoiceRow = this.agGrid.api.getSelectedRows()[0] as InvoiceGridOutput;
        if (this.isGranted('Pages.Administration.Invoices.Details')) {
            this._router.navigate(['/invoice-details', this.selectedInvoiceRow.publicId]);
        } else {
            this.showAccessError();
        }
    }

    showAccessError() {
        const title = this._appLocalizationService.l('AccessIssue');
        const message = this._appLocalizationService.l('NotAccess');
        this.toastr.error(message, title);
    }

    onSortChanged(params) {
        sessionStorage.setItem('invoice-sortModel', JSON.stringify(params.api.getSortModel()));
    }

    onFilterChanged(params) {
        sessionStorage.setItem('invoice-filterModel', JSON.stringify(params.api.getFilterModel()));
    }

    loadGridParametersFromStorage() {
        const sortModel = JSON.parse(sessionStorage.getItem('invoice-sortModel'));
        const filterModel = JSON.parse(sessionStorage.getItem('invoice-filterModel'));
        if (sortModel) {
            this.gridApi.setSortModel(sortModel);
        }
        if (filterModel) {
            this.gridApi.setFilterModel(filterModel);
        }
    }

    resetFilters() {
        sessionStorage.setItem('invoice-filterModel', null);
        sessionStorage.setItem('invoice-search-text', '');
        this.filterText = '';
        this.gridApi.setFilterModel(null);
    }

    resetSort() {
        // reset to default as defined in colDefs
        this.gridApi.setSortModel([{ colId: 'InvoiceNumber', sort: 'desc' }]);
    }
}
